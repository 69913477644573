import {Controller} from "@hotwired/stimulus";
import {useDebounce} from "stimulus-use";

export  default class extends Controller {

    static targets = ['shippingoption', 'paymentoption', 'itemQuantity', 'cartcontent']

    static values = {
        shippingId: Number|null,
        paymentId: Number|null,
        url: String,
        cartId: Number,
        itemId: Number,
        newQuantity: Number,

    }

    static debounces = ['reload_cart'];

    connect() {
        useDebounce(this, {wait: 600});
    }

    shipping_change(event){
        const clickedShippingId = event.currentTarget.dataset.shipid;
        this.shippingIdValue = clickedShippingId;

        this.reload_cart();
    }

    payment_change(event){
        this.paymentIdValue = event.currentTarget.dataset.paymentid;

        this.reload_cart();
    }

    async change_quantity(event){
        this.newQuantityValue = event.currentTarget.value;
        this.itemIdValue = event.currentTarget.dataset.itemId;

        this.reload_cart();
        // this.reload_cart(itemId, newQuantity);
    }

    async reload_cart(){
        const params = new URLSearchParams({
            shippingId: this.shippingIdValue,
            paymentId: this.paymentIdValue,
            itemId: this.itemIdValue,
            quantity: this.newQuantityValue,
            cartChanged: true,
        })

        const response = await fetch(`${this.urlValue}?${params.toString()}`);
        this.cartcontentTarget.innerHTML = await  response.text();
    }


}