import {Controller} from "@hotwired/stimulus";
import {useDebounce} from "stimulus-use";

export  default class extends Controller {

    static targets = ['addToCartButton', 'productQuantity']

    connect() {
        console.log('Product controller connected')
    }

    addToCart(e){
        // e.preventDefault();
        var productID = this.addToCartButtonTarget.dataset.productid
        var combinationID = this.addToCartButtonTarget.dataset.combinationid
        var quantity = this.productQuantityTarget.value

        console.log(productID, combinationID, quantity)
    }
}